<script lang="ts" setup>
import { IconName, IconSize } from '@leon-hub/icons';
import { CasinoRouteName } from '@leon-hub/routing-config-names';

import type { VIconProps } from '@components/v-icon';
import { CircleColor } from '@components/circular-progress';
import { VIcon } from '@components/v-icon';

import CasinoLoyaltyProgramProgress
  from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramProgress/CasinoLoyaltyProgramProgress.vue';
import {
  useCasinoSidebarLoyaltyStatus,
} from 'web/src/modules/casino/submodules/lobby/views/CasinoSidebarRouteComponent/composables';

import type { CasinoSidebarBonusBlockProps } from './types';

withDefaults(defineProps<CasinoSidebarBonusBlockProps>(), {});

const arrowIcon: VIconProps = {
  name: null ? IconName.SLOTT_ANGLE_SMALL_RIGHT : IconName.EXPAND_RIGHT,
  size: IconSize.SIZE_16,
};

const circleColor = CircleColor.PURPLE;

const {
  loyaltyProgress,
  loyaltyCard,
  pointsText,
} = useCasinoSidebarLoyaltyStatus();
</script>

<template>
  <div v-auto-id="'CasinoSidebarBonusBlock'"
    :class="$style['casino-sidebar-bonus-block']"
  >
    <router-link
      v-if="!isLoading"
      :class="$style['casino-sidebar-bonus-block__body']"
      :to="$to(CasinoRouteName.CASINO_LOYALTY_PROGRAM)"
    >
      <div
        :class="$style['casino-sidebar-bonus-block__left']"
      >
        <CasinoLoyaltyProgramProgress
          :class="$style['casino-sidebar-bonus-block__progress']"
          :circle-color="circleColor"
          v-bind="loyaltyProgress"
          :image-src="require('web/src/assets/images/beginner.svg')"
        />
        <div
          :class="$style['casino-sidebar-bonus-block__info']"
        >
          <p
            :class="$style['casino-sidebar-bonus-block__title']"
          >
            {{ loyaltyCard.levelTitle }}
          </p>
          <span
            :class="$style['casino-sidebar-bonus-block__label']"
          >{{ pointsText }}</span>
        </div>
      </div>
      <div>
        <VIcon
          :class="$style['casino-sidebar-bonus-block__icon']"
          v-bind="arrowIcon"
        />
      </div>
    </router-link>
    <div
      v-else
      :class="$style['casino-sidebar-bonus-block__loader']"
    >
      <div :class="$style['casino-sidebar-bonus-block__loader-avatar']">
        <span
          class="skeleton-loading"
          :class="$style['casino-sidebar-bonus-block__loader-circle']"
        />
      </div>
      <div :class="$style['casino-sidebar-bonus-block__loader-text']">
        <span
          class="skeleton-loading"
          :class="$style['casino-sidebar-bonus-block__loader-line-1']"
        />
        <span
          class="skeleton-loading"
          :class="$style['casino-sidebar-bonus-block__loader-line-2']"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.casino-sidebar-bonus-block {
  display: flex;
  align-items: center;
  width: 100%;
  height: 72px;
  padding: $bonusBlockPadding;
  margin-bottom: $bonusBlockMarginBottom;
  cursor: pointer;
  background-color: $bonusBlockBackgroundColor;
  border: $bonusBlockBorder;
  border-radius: $bonusBlockBorderRadius;

  &:hover {
    background-color: var(--Highlight);
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-decoration: auto;
  }

  &__left {
    display: flex;
    gap: 12px;
    justify-content: flex-start;
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__title {
    @include bonusBlockTitleFont;

    width: 120px;
    padding-right: 12px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $bonusBlockTitleColor;
    white-space: nowrap;
  }

  &__label {
    @include bonusBlockLabelFont;

    color: $bonusBlockLabelColor;
  }

  &__icon {
    color: $bonusBlockIconColor;
  }

  &__loader {
    display: flex;
    gap: 12px;
    justify-content: flex-start;

    &-avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
    }

    &-circle {
      width: 36px;
      height: 36px;
      background-color: var(--Layer2);
      border-radius: 50%;
    }

    &-text {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 6px 0;
    }

    &-line-1 {
      width: 84px;
      height: 9px;
      background-color: var(--Layer2);
      border-radius: 20px;
    }

    &-line-2 {
      width: 48px;
      height: 7px;
      background-color: var(--Layer2);
      border-radius: 20px;
    }
  }
}
</style>
